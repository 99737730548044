
.dialog-header-container {
    @apply bg-black p-5 text-3xl rounded-t-lg;
}

.atd {
    @apply grid place-items-center;
}

.atd h1 {
    @apply text-3xl text-gray-300;
}

.new-event {
    @apply flex;
}

.new-event h2 {
    @apply text-xl text-gray-300 font-thin uppercase;
}

.atd p {
    @apply text-xs text-gray-500 m-2;
}

.gen-form {
    @apply w-full;
}

.loader {
    @apply w-full;
}

.loader p {
    @apply text-xs text-gray-500 font-thin;
}