
.host-container {
    background-size: 100%
    // background: url(https://psdfreebies.com/wp-content/uploads/2021/11/Weekend-Club-Party-Flyer-PSD-Template-Preview-730x1024.jpg);
    // @apply rotate-12;
}

.host {
    @apply text-gray-700 text-sm -mt-2 mb-4 font-light uppercase;
}

.host-msg {
    @apply p-6 bg-black/80 my-7 text-white text-left;
}

.host-msg h2 {
    @apply text-white/80 my-3 uppercase;
}

.host-msg p {
    @apply text-xs text-gray-400 m-5 leading-5;
}

.line {
    @apply border border-gray-700 mt-2 mb-4;
}

.oops1 {
    @apply place-items-center text-center text-sm border text-pink-600 border-pink-600 px-6 py-2 mx-6 rounded-lg;
}