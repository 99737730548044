

.navbar-container {
    // width: 100%;
    // position: fixed;
    // z-index: 10;
    @apply w-full fixed top-0 z-10 grid place-items-center;
    // @apply sticky w-full grid place-items-center mb-10;
}

.navbar-content {
    width: 500px;
    @apply w-full flex bg-gray-900 px-4 py-2 text-gray-300;
}

.nav-left {
    @apply w-1/2;
}

.nav-right {
    @apply w-1/2;
}

.nav-left h1 {
    @apply font-medium text-xl uppercase mt-1;
}

.icon-btn-r {
    @apply m-1 float-right hover:bg-gray-700;
}

.nav-left img {
    @apply w-10 h-9 mt-1 rounded-md;
}