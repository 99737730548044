
.profile-container {
    @apply bg-white rounded-lg;
}

.profile-img-cont {
    @apply bg-white rounded-t-2xl h-40 shadow-lg;
    background: url(https://t4.ftcdn.net/jpg/05/22/65/47/360_F_522654750_dWty6iO9Ev0Xjqlmorg6QV04sgKpmkUN.jpg);
    background-size: 100%;
    /* opacity: 0.4;
    border: 2px solid #ff9100; */
}

.profile-img {
    @apply w-full;
    height: 162px;
}

.items-input-group {
    @apply 
    w-[calc(80%-1px)] mx-[calc(20%/2)] 
    my-3;
}

.items-input-group p {
    @apply text-right text-xs font-light;
}

.box2
{
    @apply w-44 h-44 shadow-lg;
    background-color: #faebd7;
    clip-path: polygon(50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);
    margin: 0 auto;
    border: 3px solid #fff;
}

.flt_svg2 {
    visibility: hidden; 
    position: absolute;
    width: 0px;
    height: 0px;
}

.box_parent
{
    margin-top: -167px;
    filter: url('#flt_tag');
}

.box_parent2
{
    margin-top: -100px;
    filter: url('#flt_tag');
}

.profile-text {
    @apply text-center m-3 p-3;
}

.profile-text h2 {
    @apply font-medium mb-1;
}

.profile-text p {
    @apply font-light text-xs mb-0.5;
}

.item-name {
    @apply text-sm;
}

.item-description {
    @apply text-xs font-light text-blue-gray-500;
}

.home-container {
    @apply w-full min-h-[calc(100vh-140px)] shadow-lg rounded-lg my-5 relative bg-no-repeat;
    // background: url(https://firebasestorage.googleapis.com/v0/b/sproude-pos.appspot.com/o/files%2F01ea96d5-c72b-4de8-ab34-75207feed74d?alt=media&token=e40d5a1b-7890-4e8f-be19-3da2c41de7ba);
    background: url('../../assets/bg1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
}

.sid-container {
    @apply w-full mt-10 px-12;
}






@media (max-width: 480px) {
    .sid-container {
        @apply px-8;
    }
}
