
.time_banner {
    font-size: 0.6em;
    @apply flex w-full text-white mb-2 font-medium;
}

.tb1 {
    border-radius: 10px 0 0 0;
    @apply px-2 py-1 pr-2 bg-gray-950;
}

.tb2 {
    border-radius: 0 3px 3px 0;
    @apply px-2 py-1 bg-green-600;
}

.tb3 {
    border-radius: 0 3px 3px 0;
    @apply px-2 py-1 bg-red-600;
}

.partner-logo img {
    @apply rounded-md;
}

.ticket-header {
    @apply p-2;
}

.ticket-header h2 {
    @apply text-2xl;
}

.ticket-header p {
    @apply px-2 text-xs text-gray-500 capitalize;
}

.steps {
    @apply flex mb-4;
}

.steps p {
    @apply mx-2 p-2 text-xs uppercase font-medium;
}

.step1 {
    @apply flex border-b-4 w-1/3 border-red-600 rounded-t-md;
}

.step2 {
    @apply flex border-b-4 w-1/3 border-yellow-500 rounded-t-md;
}

.step3 {
    @apply flex border-b-4 w-1/3 border-green-600 rounded-t-md;
}

.succes_tag {
    @apply text-xs m-2 p-5 bg-green-300/50 text-green-700 rounded-md;
}

.succes_tag span {
    @apply text-xs py-0.5 mt-2 rounded-md p-2 bg-green-100 hover:bg-white leading-6 border border-green-600;
}