
.social-links-container {
    @apply border rounded-md py-2 my-4;
}

.event-row-container {
    @apply w-full flex border rounded-md hover:shadow-md my-2;
}

.ev-flyer-col {
    @apply w-20 rounded-l-md;
}

.ev-details {
    @apply w-[calc(100%-130px)] px-4 my-2 text-left border-r border-r-red-300 mr-3;
}

.ev-details h1 {
    @apply uppercase leading-5;
}

.ev-details h2 {
    @apply text-sm font-light pb-1.5;
}

.ev-details h4 {
    @apply text-xs font-normal pb-1.5 uppercase;
}

.ev-details p {
    @apply text-xs font-thin uppercase text-gray-400;
}

.ev-actions {
    @apply py-2 flex flex-col;
}



.selected-contacts-container {
    // width: 300px;
    // min-height: 300px;
    @apply w-[calc(100%-16px)] bg-gray-500 mx-2 mb-4 border rounded-lg;
}

.selected-contacts {
    @apply w-full max-h-52 p-1 pb-2 overflow-auto bg-white/30 rounded-b-lg;
}

.search-items {
    @apply border min-w-max min-h-8 text-xs bg-blue-gray-50 rounded-md m-1.5 px-3 py-2 hover:border-blue-gray-500 hover:bg-white/70;
}

.added-items {
    @apply border border-green-300 min-w-max min-h-8 text-xs bg-green-300 rounded-md m-1.5 px-3 py-2 hover:border-green-500 hover:bg-green-400;
}

.added-items-clear {
    @apply border border-red-300 min-w-max text-xs text-red-600 bg-none rounded-md m-1 px-3 py-2 hover:border-red-500 hover:bg-red-50;
}

.search-item-x {
    @apply float-right border border-gray-400 rounded-md hover:bg-white hover:text-red-500 p-1 -mt-1;
}

.search-item-save {
    @apply float-right hover:rounded-sm hover:bg-white/90 hover:text-red-500 p-0.5 -mt-1;
}

.search-item-add {
    @apply float-right border border-gray-400 rounded-md hover:bg-green-200 hover:text-gray-700 p-0.5 -mt-1 px-2;
}

.attendees {
    @apply float-right rounded-md bg-red-500 pl-2 py-0.5 text-white hover:opacity-80 mt-1;
}

.attendees b {
    @apply rounded-md bg-green-500 px-2 py-1 ml-1.5 font-medium;
}

.attendance-header {
    @apply border-b-2 border-b-light-blue-500 mb-4;
}

.attendance-header p {
    @apply text-xs uppercase pb-2;
}

// .attendance-body {
//     @apply border-b;
// }

.attendance-body p {
    @apply text-xs capitalize pb-2;
}

.attendance-body h4 {
    @apply text-xs italic px-2 pb-2 capitalize border-b;
}


// Contacts

.contacts-head {
    // border-bottom: 3px solid #00d0ff22
    @apply border-b;
}

.contacts-head h1 {
    @apply capitalize font-medium text-2xl;
}

.contacts-head p {
    font-size: 0.8em;
    @apply m-1 px-2 py-1 text-gray-500 float-left hover:text-blue-600;
}

.contact {
    @apply border-b mt-2 px-2 py-1;
}

.contact h3 {
    @apply capitalize;
}

.contact h4 {
    font-size: 0.8em;
    @apply text-gray-500;
}

.contact h4 span {
    @apply text-blue-600;
}

.contact p {
    font-size: 0.8em;
    @apply m-1 px-2 py-1 text-gray-500 float-right hover:text-blue-600;
}

.horizontal-break {
    @apply h-full border border-r-gray-400 bg-none hover:bg-none;
}

// .contact p span {
//     @apply float-left;
// }