

body {
    @apply bg-orange-300/10;
}


.general-container-wrapper {
    @apply w-full grid place-items-center mt-12;
}

.general-container-size {
    width: 500px;
    @apply mt-5 bg-red-500/0;
}

.pop {
    @apply flex rounded-md my-5 hover:shadow-lg;
}

.pop-left {
    background: #ff0037;
    border: 1px solid #ff0037;
    @apply w-20 rounded-l-md border;
}

.pop-right {
    border: 1px solid #ff0037;
    @apply w-[calc(100%-80px)] bg-white/10 rounded-r-md border px-5 py-3 uppercase text-left tracking-wide hover:shadow-lg;
}

.pop-icon {
    @apply mx-7 my-4 text-white;
}

.pop-right h2 {
    @apply font-medium;
}

.pop-right p {
    font-size: 0.6em;
    /* @apply text-xs; */
}

.social-icon {
    @apply text-gray-200 hover:text-red-500;
}

.cardbody-with-bg {
    width: 100%;
    /* background: url('https://firebasestorage.googleapis.com/v0/b/sproude-pos.appspot.com/o/External%2FBg-f1f1f1_2.jpg?alt=media&token=52f5cd9e-b13d-415a-a0d0-d318cc46066e'); */
    background-size: 200%;
}

.pivoapps-footer {
    @apply w-full grid place-content-center p-4 absolute bottom-5;
}

.pivoapps-footer p {
    @apply text-xs text-gray-500 font-light;
}

.pivoapps-footer img {
    @apply w-10;
}

.blue-text {
    @apply text-xs text-blue-600 m-2;
}

.dialog-container {
    @apply max-h-[calc(75vh-10px)] overflow-y-auto;
}

/* .action-btn {
    @apply p-1 ml-2 border border-gray-400 rounded-md hover:bg-gray-200;
} */
  
.del-btn {
    @apply p-1.5 m-0.5 rounded-md bg-transparent text-gray-800 hover:shadow-lg border border-blue-gray-100 hover:border hover:border-red-500 hover:text-red-500 float-right;
}
  
.action-btn {
    @apply p-1.5 m-0.5 rounded-md bg-transparent text-gray-800 hover:shadow-lg border border-blue-gray-100 hover:border hover:border-blue-gray-500 hover:bg-white float-right;
}

.page-header {
    @apply text-xl font-thin mb-4;
}

.tooltip-des {
    @apply bg-gray-200/85 text-gray-600 rounded-sm;
}

.custom-select {
    @apply w-full border border-blue-gray-200 px-2 py-2.5 outline-none rounded-md placeholder:text-blue-gray-400 text-sm font-normal;
}

.qr-right {
    @apply border rounded-md p-4;
}

.qr-right input {
    @apply w-2/4 mx-2 px-2 py-2.5 text-sm rounded-md border border-gray-300;
}






@media (max-width: 480px) {
    .general-container-size {
        @apply w-[calc(100%-16px)];
    }
    
    .home-container {
        @apply w-[calc(100%-8px)] min-h-[calc(100vh-10px)] bg-no-repeat;
        /* background: url(https://firebasestorage.googleapis.com/v0/b/sproude-pos.appspot.com/o/files%2Fc1620365-bb82-4f9f-bb7e-f2d4294432f8?alt=media&token=0533247b-922f-4a98-a13a-4e23925b0e4b); */
        background-size: 100%;
    }
}