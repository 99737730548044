
.main-div {
    width: 80%;
    margin: 0 auto;
    background: turquoise;
}

.navbar {
    width: 100%;
    display: flex;
    background: #000;
    margin-bottom: 50px;
}

.logo {
    padding: 5px 10px;
    color: #fff;
    float: left;
    background: tomato;
}

.logo h2 {
    color: #fff;
    font-size: 0.7em;
    font-weight: 500;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.mid-nav {
    color: #b0b0b0;
    display: flex;
    /* background: gold; */
    font-size: 0.5em;
}

.mid-nav p {
    padding: 4px 5px 4px 5px;
    margin: 4px;
    /* background: blueviolet; */
    border-radius: 2px;
}

.mid-nav p:hover {
    opacity: 0.7;
    background: #cccccc6c;
}

.contact-button {
    color: #fff;
    font-size: 0.5em;
    text-transform: capitalize;
    padding: 2px 15px;
    background: teal;
    border-radius: 7px;
}

.contact-button:hover {
    opacity: 0.7;
}

.right-nav {
    float: right!important;
    background: turquoise;
}