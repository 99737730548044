
/* .general-container-size {
    width: 800px;
    @apply my-5;
} */

.general-container-wrapper {
    @apply w-full grid place-items-center mb-10;
}

.home-menu-card {
    transition: all .4s ease-in-out;
    @apply w-1/3 text-blue-gray-700 hover:scale-125 p-4 m-2.5 bg-white/90 rounded-lg shadow-md;
}

.home-menu-container {
    @apply w-full bg-green-500;
}

.home-header {
    @apply text-blue-gray-800 text-base font-medium capitalize mt-5;
}

.home-sub-header {
    @apply text-xs uppercase text-blue-gray-600;
}

.pivoapps img {
    @apply w-14;
}

.pivoapps h4 {
    font-size: 0.5em;
    @apply uppercase font-light tracking-wider text-gray-600;
}

.pivoapps {
    @apply bottom-4 left-4 absolute;
}

.spoude {
    @apply text-center pt-[calc(30vh-1px)];
}

.spoude h2 {
    font-size: 2em;
    @apply uppercase font-extrabold text-white tracking-tighter;
}

.spoude h4 {
    @apply text-xs uppercase font-light;
}

.upgrade {
    @apply w-1/2 px-5 py-2 m-2 bg-gray-900 text-gray-300 rounded-sm text-xs uppercase hover:opacity-80 mx-[calc(50%/2)];
}

/* .home-sub-header {
    @apply;
} */